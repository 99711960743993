import {
  Checkbox,
  Col,
  Form,
  Modal,
  Row,
  Select,
  Space,
  Tag,
  Typography,
} from "antd";
import { useMemo, useState } from "react";
import { StyledButton } from "src/styled_components/StyledButton";
import jwtManager from "src/utils/jwtManager";
import useTranslate from "src/utils/useTranslate";
import { Headers } from "src/services/interface";
import { StoreState } from "src/store/configureStore";
import { useDispatch, useSelector } from "react-redux";
import RepositoryService from "src/services/repository/v3";
import { displayNamesRequest } from "src/store/actions/displayNames";

const { Text } = Typography;

type TProps = {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedPropertyId: string;
  specimens: any[];
  setSpecimens: React.Dispatch<React.SetStateAction<any[]>>;
};

const PropertySpecimenModal = ({
  showModal,
  setShowModal,
  selectedPropertyId,
  specimens,
  setSpecimens,
}: TProps) => {
  const [t] = useTranslate();
  const [propertySpecimenForm] = Form.useForm();
  const [searchValue, setSearchValue] = useState<string>("");
  const [newSpecimentOptions, setNewSpecimentOptions] = useState<any[]>([]);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  const defaultHeaders = useSelector(
    (state: StoreState) => state.defaultHeaders
  );

  const speciemenOptions = useMemo(() => {
    if(searchValue.length > 0) return [...new Set([...specimens, searchValue, ...newSpecimentOptions])];
    else return [...new Set([...specimens, ...newSpecimentOptions])];
  }, [searchValue, specimens, newSpecimentOptions])

  const saveSpecimens = async (payload: any) => {
    setLoading(true);
    const headers = {
      ...defaultHeaders,
      token: jwtManager.getToken(),
    } as Headers;
    const specimensResponse: any = await RepositoryService.updateSpecimens(
      selectedPropertyId,
      payload,
      headers
    );
    setSpecimens(payload.specimens);
    setLoading(false);
    dispatch(displayNamesRequest({ backgroundFetch: true }));
    propertySpecimenForm.resetFields();
    setShowModal(false);
    console.log(specimensResponse.data);
  };

  // useEffect(() => {
  //   propertySpecimenForm.setFieldValue("specimens", specimens);
  // }, [selectedPropertyId]);

  const submit = () => {
    const specimens = propertySpecimenForm.getFieldValue("specimens").map((v: string) => v.trim());
    const payload = {
      property_id: selectedPropertyId,
      ...propertySpecimenForm.getFieldsValue(),
      specimens
    };

    saveSpecimens(payload);

    // setSpecimens(specimensResponse.data?.result?.data?.specimens ?? []);
  };

  const handleChange = (value: any) => {
    setSearchValue("");
    setNewSpecimentOptions([...new Set([...newSpecimentOptions, ...value])]);
  };

  return (
    <Modal
      title={
        <Typography.Title level={4}>
          {t("inventory.updateSpecimens")}
        </Typography.Title>
      }
      open={showModal}
      onOk={submit}
      onCancel={() => {
        propertySpecimenForm.resetFields();
        setShowModal(false);
      }}
      footer={null}
      destroyOnClose
    >
      <Form
        onFinish={submit}
        form={propertySpecimenForm}
        layout="vertical"
        scrollToFirstError
        initialValues={{
          specimens,
        }}
      >
        <Form.Item
          name={"specimens"}
          label={t("inventory.updateSpecimens")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder={t("common.select")}
            showSearch={true}
            filterOption={false}
            onSearch={(value) => {
              if (!value.startsWith(' ')) {
                setSearchValue(value);
              }
            }}
            searchValue={searchValue}
            maxTagCount="responsive"
            onChange={handleChange}
            onInputKeyDown={(event) => {
              if (event.key === "Backspace") {
                return event.stopPropagation();
              }
            }}
            tagRender={(props) => (
              <Tag {...props} closable={!specimens?.includes(props.value)}>
                {props.label}
              </Tag>
            )}
            
            onDeselect={(value) => {
              if (specimens?.includes(value)) {
                const updatedSpecimens = [...new Set([...specimens, ...propertySpecimenForm.getFieldValue("specimens")])]
                propertySpecimenForm.setFieldValue("specimens", updatedSpecimens);
              }
            }}
            optionRender={(option) => (
              <Space
                direction="horizontal"
              >
                <Checkbox
                  disabled={specimens?.includes(option.value)}
                  checked={
                    propertySpecimenForm
                      .getFieldValue("specimens")
                      ?.includes(option.value) ||
                    specimens?.includes(option.value)
                  }
                />
                <Text style={{ whiteSpace: "initial" }}>{option.label}</Text>
              </Space>
            )}
            options={speciemenOptions.map((item) => {
              return {
                label: item,
                value: item,
              };
            })}
          />
        </Form.Item>

        <Row gutter={[8, 8]} justify={"end"} style={{ marginTop: "20px" }}>
          <Col>
            <StyledButton
              type="default"
              onClick={() => {
                propertySpecimenForm.resetFields();
              }}
            >
              {t("common.reset")}
            </StyledButton>
          </Col>
          <Col>
            <StyledButton disabled={loading} type="primary" htmlType="submit">
              {t("common.submit")}
            </StyledButton>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default PropertySpecimenModal;
