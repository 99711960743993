import {
  CheckCircleFilled,
  CloseCircleFilled,
  DownloadOutlined,
  ExclamationCircleFilled,
  ReloadOutlined
} from '@ant-design/icons'
import { Space, Typography, Tooltip, Spin, Row, Select, Segmented } from 'antd';
import { useState, useEffect, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { antdTheme, AsyncStates } from 'src/constants'
import EditableTable from 'src/modules/PolyGPT/components/EditableTable'
import {
  qcSaveChangesAndApplyRequest,
  qcSetPageNumber,
  qcSetIsReportSectionUnsaved
} from 'src/store/actions/qualityCheck'
import { StoreState } from 'src/store/configureStore'
import useTranslate from 'src/utils/useTranslate'
import { QualityCheckStatus } from '..'
import Status from '../FilesTable/Status'
import TestResult from '../FilesTable/TestResult'
import { StyledButton } from 'src/styled_components/StyledButton'

const ViewReport = ({ numPages, openReportSection }: any) => {
  const [t] = useTranslate()
  const dispatch = useDispatch()
  const {
    pageNumber,
    fileExtractionsResponse,
    selectedFile,
    fileExtractionsStatus,
    isReportSectionUnsaved
  } = useSelector((state: StoreState) => state.qualityCheck)

  const [selectedTableIdx, setSelectedTableIdx] = useState<number>(0)
  const [selectedTable, setSelectedTable] = useState<any>()
  const [selectedPage, setSelectedPage] = useState<any>()

  useEffect(() => {
    setSelectedPage(fileExtractionsResponse?.extractions?.[`${pageNumber}`])
  }, [fileExtractionsResponse?.extractions, pageNumber])

  useEffect(() => {
    setSelectedTable(selectedPage?.tables?.[0])
  }, [selectedPage?.tables])

  const metadataColums = useMemo(() => {
    return [
      { title: 'Key', dataIndex: 'key', editable: true, width: '30%' },
      { title: 'Value', dataIndex: 'value', editable: true, width: '30%' }
    ]
  }, [])

  const metadataDatasource = useMemo(() => {
    const metadata = selectedPage?.customer_info
    return Object.keys(metadata || {})?.map((key) => ({
      key,
      value: metadata[key]
    }))
  }, [selectedPage?.customer_info])

  const selectTableOptions = useMemo(() => {
    return selectedPage?.tables?.map((table: any, idx: number) => ({
      label: `Table ${idx + 1}`,
      value: idx
    }))
  }, [selectedPage?.tables])

  const extractedTableColums = useMemo(() => {
    const renderResults = (text: any) => {
      let component: JSX.Element | null = null

      switch (text) {
        case 'Pass':
        case 'pass':
          component = (
            <CheckCircleFilled style={{ color: '#52C41A', fontSize: antdTheme.fontSize }} />
          )
          break
        case 'Fail':
        case 'fail':
          component = (
            <CloseCircleFilled style={{ color: '#FF4D4F', fontSize: antdTheme.fontSize }} />
          )
          break
        case 'Alert':
        case 'alert':
          component = (
            <Tooltip title={t('qc.alert')}>
              <ExclamationCircleFilled style={{ color: '#FAAD14',  fontSize:antdTheme.fontSize }} />
            </Tooltip>
          )
          break
        default:
          break
      }
      return component
    }

    const renderInputCriteria = ((criteria: any) => {
      const operator = criteria?.operator || ''
      const value = criteria?.value || ''

      let output = `${operator} ${value}`

      if(operator === 'or'){
        output = `${value.join(', ')}`
      }

      if(operator === 'range'){
        output = `${value[0]} - ${value[1]}`
      }

      if(operator === '='){
        output = `${value}`
      }

      return <Typography.Text style={{
        maxWidth: '200px',
      }} ellipsis={{
        tooltip: output
      }} >{output}</Typography.Text>
    })

    return Object?.keys(selectedTable?.table?.[0] || {})?.map((key: any) => ({
      title: key,
      dataIndex: key,
      key: key,
      editable: key === 'Pass/Fail' || key === 'Input Criteria' ? false : true,
      ...(key === 'Pass/Fail'
        ? { render: (text: any) => renderResults(text), width: '150px' }
        : {}),
      ...(key === 'Input Criteria' ? { render: (text: any) => renderInputCriteria(text), width: 250 } : {})
    }))
  }, [selectedTable?.table, t])

  const extractedTableDatasource = useMemo(() => {
    return selectedTable?.table?.map((row: any, index: number) => ({
      key: index,
      ...row,
    }))
  }, [selectedTable?.table])

  const handleTableNavigation = useCallback(
    (value: any) => {
      const table = selectedPage?.tables?.[value]
      setSelectedTable(table)
      setSelectedTableIdx(value)
    },
    [selectedPage?.tables]
  )

  const handleExcelDownload = useCallback(() => {
    return window.open(selectedTable?.s3_excel_link, '_blank')
  }, [selectedTable?.s3_excel_link])

  const handleSaveAndRerun = useCallback(() => {
    dispatch(
      qcSaveChangesAndApplyRequest({
        file_id: selectedFile?.file_id,
        page_no: String(pageNumber),
        table_id: selectedTable?.table_id,
        updated_table: selectedTable?.table?.map((row: any) => {
          const updatedRow = JSON.parse(JSON.stringify(row))
          delete updatedRow.key
          return updatedRow
        }),
        updated_metadata: selectedPage?.customer_info
      })
    )
  }, [
    dispatch,
    pageNumber,
    selectedFile?.file_id,
    selectedPage?.customer_info,
    selectedTable?.table,
    selectedTable?.table_id
  ])

  return (
    <Space
      direction="vertical"
      style={{
        background: 'white',
        width: '70%',
        padding: '20px',
        margin: 10,
        overflow: 'auto',
        userSelect: 'none',
        ...(!openReportSection ? { display: 'none' } : {})
      }}
      className="report-viewer-container"
    >
      <Spin spinning={fileExtractionsStatus === AsyncStates.LOADING}>
        <Space direction="vertical" style={{ width: '100%' }}>
          {/* Top Section - Heading and Page Navigation */}
          <Row style={{ width: '100%', justifyContent: 'space-between' }}>
            <Space size={'small'}>
              <Typography.Title
                level={5}
                style={{ margin: 0 }}
              >
                {t('workOrders.stage.reportPreview')}
              </Typography.Title>
              {Object.keys(selectedFile?.qc_details || {}).length &&
                selectedFile?.status === QualityCheckStatus.COMPLETED && (
                  <div style={{ display: 'flex' }}>
                    <TestResult qc_details={selectedFile?.qc_details} />
                    <Status qc_details={selectedFile?.qc_details} status={selectedFile?.status} />
                  </div>
                )}
            </Space>

            <Space>
              <StyledButton
                type="default"
                style={{ outline: 'none' }}
                icon={<DownloadOutlined />}
                onClick={handleExcelDownload}
                disabled={!selectedTable?.s3_excel_link}
                className="animated__hover__btn"
              >
                <span className="animated__hover__content">
                  {t('report.downloadReport')}
                </span>
              </StyledButton>
              <StyledButton
                type="primary"
                style={{ outline: 'none' }}
                icon={<ReloadOutlined />}
                onClick={handleSaveAndRerun}
                disabled={!selectedTable?.table_id || !isReportSectionUnsaved}
                className="animated__hover__btn"
              >
                <span className="animated__hover__content">{`${t(
                  'common.save'
                )} & ${t('common.rerun')}`}</span>
              </StyledButton>
              {numPages && pageNumber && (
                <div>
                  <Select
                    className="custom-select"
                    value={pageNumber}
                    style={{ width: 120 }}
                    onChange={(value) => dispatch(qcSetPageNumber(value))}
                    options={Array.from({ length: numPages }, (_, i) => ({
                      label: `${t('polygpt.Page')} ${i + 1}`,
                      value: i + 1
                    }))}
                  />
                </div>
              )}
            </Space>
          </Row>

          {/* Middle Section - Customer Meta Info */}
          <EditableTable
            columns={metadataColums}
            datasource={metadataDatasource}
            showHeader={false}
            updateDatasource={(updatedData: any) => {
              const updatedMetadata = updatedData.reduce(
                (acc: any, curr: any) => {
                  acc[curr.key] = curr.value
                  return acc
                },
                {}
              )
              setSelectedPage((prevState: any) => ({
                ...prevState,
                customer_info: updatedMetadata
              }))
              dispatch(qcSetIsReportSectionUnsaved(true))
            }}
          />

          {/* Table Section - Table Navigation and Tables */}
          <Segmented
            options={selectTableOptions}
            value={selectedTableIdx}
            onChange={handleTableNavigation}
            style={{
              fontWeight: 600,
            }}
          />

          <EditableTable
            columns={extractedTableColums}
            datasource={extractedTableDatasource}
            updateDatasource={(updatedData: any) => {
              setSelectedTable((prevState: any) => ({
                ...prevState,
                table: updatedData
              }))
              dispatch(qcSetIsReportSectionUnsaved(true))
            }}
          />
        </Space>
      </Spin>
    </Space>
  )
}

export default ViewReport
