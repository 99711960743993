import { call, put, select, takeLatest } from 'redux-saga/effects'
import jwtManager from 'src/utils/jwtManager'
import {
  applyForQC,
  getQCFiles,
  deleteFile,
  getFileExtractions,
  saveChangesAndApply,
  getRegisterLinkData,
  linkRecurringCycle,
  syncOnDemand,
  validateLink,
  generateReport,
  createNameDetails,
  getNameDetails,
  updateNameDetails,
  deleteNameDetails,
  getMetadataList,
  getMetadata,
  createMetadata,
  updateMetadata,
  deleteMetadata,
  getMetadataListShort,
  importData
} from 'src/services/qualityCheck'
import {
  qcGetFilesSuccess,
  qcGetFilesFailure,
  qcGetFilesRequest,
  qcApplyFilesSuccess,
  qcApplyFilesFailure,
  qcApplyFilesRequest,
  qcFileExtractionsRequest,
  qcFileExtractionsFailure,
  qcFileExtractionsSuccess,
  qcSaveChangesAndApplyFailure,
  qcSaveChangesAndApplySuccess,
  qcSaveChangesAndApplyRequest,
  qcSetIsReportSectionUnsaved,
  qcValidateLinkFailure,
  qcValidateLinkSuccess,
  qcLinkRecurringCycleFailure,
  qcLinkRecurringCycleSuccess,
  qcSyncOnDemandFailure,
  qcSyncOnDemandSuccess,
  qcLinkRecurringCycleRequest,
  qcSyncOnDemandRequest,
  qcValidateLinkRequest,
  qcGetRegisterLinkDataSuccess,
  qcGetRegisterLinkDataFailure,
  qcGetRegisterLinkDataRequest,
  qcSetRegisterLinkModalVisible,
  qcGenerateReportSuccess,
  qcGenerateReportFailure,
  qcGenerateReportRequest,
  qcDeleteFileSuccess,
  qcDeleteFileFailure,
  qcDeleteFileRequest,
  qcSetGetFilesPageNumber,
  qcClearSelectedFilesForReport,
  qcSetFileUploadModalVisible,
  qcMetadataListSuccess,
  qcMetadataListFailure,
  qcMetadataListRequest,
  qcGetMetadataSuccess,
  qcGetMetadataFailure,
  qcGetMetadataRequest,
  qcGetNameDetailsRequest,
  qcGetNameDetailsSuccess,
  qcGetNameDetailsFailure,
  qcCreateNameDetailsRequest,
  qcUpdateNameDetailsRequest,
  qcDeleteNameDetailsRequest,
  qcCreateNameDetailsFailure,
  qcCreateNameDetailsSuccess,
  qcUpdateNameDetailsFailure,
  qcUpdateNameDetailsSuccess,
  qcDeleteNameDetailsFailure,
  qcDeleteNameDetailsSuccess,
  qcCreateMetadataRequest,
  qcUpdateMetadataRequest,
  qcDeleteMetadataRequest,
  qcCreateMetadataSuccess,
  qcCreateMetadataFailure,
  qcUpdateMetadataSuccess,
  qcUpdateMetadataFailure,
  qcDeleteMetadataSuccess,
  qcDeleteMetadataFailure,

  qcGetImportDataOptionsRequest,
  qcGetImportDataOptionsSuccess,
  qcGetImportDataOptionsFailure,

  qcImportDataRequest,
  qcImportDataSuccess,
  qcImportDataFailure,
  qcMetadataSetPageNumber,

} from '../actions/qualityCheck'
import { notification } from 'antd';
import { LanguageUnion } from "src/utils/useTranslate";
import { StoreState } from '../configureStore'
import { messages } from 'src/utils/hooks'


function* qcGetFilesSaga({ payload }: any): Generator<any, any, any> {
  const defaultPayload = {
    page_num: yield select((state) => state.qualityCheck.getFilesPageNumber),
    page_size: yield select((state) => state.qualityCheck.getFilesPageSize),
    filters: yield select((state) => state.qualityCheck.getFilesFilters),
    search: yield select((state) => state.qualityCheck.getFilesSerachTerm)
  }
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const {
      data: {
        result: { status, data, message }
      }
    } = yield call(getQCFiles, { ...defaultPayload, ...payload }, headers)
    if (status === 'Success') {
      yield put(qcGetFilesSuccess(data))
      yield put(qcSetFileUploadModalVisible(false))
      if(payload?.page_num){
        yield put(qcSetGetFilesPageNumber(payload.page_num))
      }
    } else {
      yield put(qcGetFilesFailure(message))
      message && notification.error({ message })
    }
  } catch (error) {
    yield put(qcGetFilesFailure(error))
  }
}

function* qcApplyFilesSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const {
      data: {
        result: { status, data, message }
      }
    } = yield call(applyForQC, payload, headers)
    if (status === 'Success') {
      yield put(qcApplyFilesSuccess(data))
      yield put(qcGetFilesRequest({ page_num: 1 }))
    } else {
      yield put(qcApplyFilesFailure(message))
      message && notification.error({ message })
    }
  } catch (error) {
    yield put(qcApplyFilesFailure(error))
  }
}

function* qcFileExtractionsSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const {
      data: {
        result: { status, data, message }
      }
    } = yield call(getFileExtractions, payload, headers)
    if (status === 'Success') {
      yield put(qcFileExtractionsSuccess(data))
    } else {
      yield put(qcFileExtractionsFailure(message))
      message && notification.error({ message })
    }
  } catch (error) {
    yield put(qcFileExtractionsFailure(error))
  }
}

function* qcSaveChangesAndApplySaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const {
      data: {
        result: { status, data, message }
      }
    } = yield call(saveChangesAndApply, payload, headers)
    if (status === 'Success') {
      yield put(qcSaveChangesAndApplySuccess(data))
      yield put(
        qcFileExtractionsRequest({
          file_id: payload.file_id
        })
      )
      yield put(qcSetIsReportSectionUnsaved(false))
    } else {
      yield put(qcSaveChangesAndApplyFailure(message))
      message && notification.error({ message })
    }
  } catch (error) {
    yield put(qcSaveChangesAndApplyFailure(error))
  }
}

function* qcDeleteFileSaga({ payload }: any): Generator<any, any, any> {
  try {
    const defaultPayload = {
      page_num: 1,
      page_size: yield select((state) => state.qualityCheck.getFilesPageSize),
      filters: yield select((state) => state.qualityCheck.getFilesFilters),
      search: yield select((state) => state.qualityCheck.getFilesSerachTerm)
    }

    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const {
      data: {
        result: { status, data, message }
      }
    } = yield call(deleteFile, payload, headers)
    if (status === 'Success') {
      yield put(qcDeleteFileSuccess(data))
      yield put(qcClearSelectedFilesForReport())
      yield put(qcGetFilesRequest(defaultPayload))
    } else {
      yield put(qcDeleteFileFailure(message))
      message && notification.error({ message })
    }
  } catch (error) {
    yield put(qcDeleteFileFailure(error))
  }
}

function* qcGetRegisterLinkDataSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const {
      data: {
        result: { status, data, message }
      }
    } = yield call(getRegisterLinkData, payload, headers)
    if (status === 'Success') {
      yield put(qcGetRegisterLinkDataSuccess(data))
    } else {
      yield put(qcGetRegisterLinkDataFailure(message))
      message && notification.error({ message })
    }
  } catch (error) {
    yield put(qcGetRegisterLinkDataFailure(error))
  }
}

function* qcValidateLinkSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const {
      data: {
        result: { status, data, message }
      }
    } = yield call(validateLink, payload, headers)
    if (status === 'Success') {
      yield put(qcValidateLinkSuccess(data))
    } else {
      yield put(qcValidateLinkFailure(message))
      message && notification.error({ message })
    }
  } catch (error) {
    yield put(qcValidateLinkFailure(error))
  }
}

function* qcLinkRecurringCycleSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const {
      data: {
        result: { status, data, message }
      }
    } = yield call(linkRecurringCycle, payload, headers)
    if (status === 'Success') {
      yield put(qcLinkRecurringCycleSuccess(data))
      yield put(qcSetRegisterLinkModalVisible(false))
      notification.success({ message })
    } else {
      yield put(qcLinkRecurringCycleFailure(message))
      message && notification.error({ message })
      yield put(qcSetRegisterLinkModalVisible(true))
    }
  } catch (error) {
    yield put(qcLinkRecurringCycleFailure(error))
  }
}

function* qcSyncOnDemandSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const {
      data: {
        result: { status, data, message }
      }
    } = yield call(syncOnDemand, payload, headers)
    if (status === 'Success') {
      yield put(qcSyncOnDemandSuccess(data))
    } else {
      yield put(qcSyncOnDemandFailure(message))
      message && notification.error({ message })
      yield put(qcSetRegisterLinkModalVisible(true))
    }
  } catch (error) {
    yield put(qcSyncOnDemandFailure(error))
  }
}

function* qcGenerateReportSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }
    const filesToReport = yield select((state) => state.qualityCheck.filesToReport)
    const isSelectAllFiles = yield select((state) => state.qualityCheck.isSelectAllFiles)

    const ln: LanguageUnion = yield select(
        (state: StoreState) => state.language.current,
      );

    const selectedFiles = Object.values(filesToReport).flat()

    const finalPayload = {
      ...payload,
      ...(!isSelectAllFiles && { file_ids: selectedFiles }),
      search: yield select((state) => state.qualityCheck.getFilesSerachTerm)
    }

    if(isSelectAllFiles || selectedFiles.length > 20){
      yield put(qcClearSelectedFilesForReport())
      notification.info({ message: messages[ln].reportGenerationInProgress })
    }

    const {
      data: {
        result: { status, data, message }
      }
    } = yield call(generateReport, finalPayload, headers)

    if (status === 'Success') {
      yield put(qcGenerateReportSuccess(data))
      yield put(qcClearSelectedFilesForReport())
      window.open(data, '_blank')
    } else {
      yield put(qcGenerateReportFailure(message))
      message && notification.error({ message })
    }
  } catch (error) {
    yield put(qcGenerateReportFailure(error))
  }
}

// Metadata

function* qcMetadataListSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }

    const defaultPayload = {
      page_num: yield select((state) => state.qualityCheck.metadataPageNumber),
      page_size: yield select((state) => state.qualityCheck.metadataPageSize),
      filters: yield select((state) => state.qualityCheck.metadataFilters),
      search: yield select((state) => state.qualityCheck.metadataSearchTerm)
    }

    const {
      data: {
        result: { status, data, message }
      }
    } = yield call(getMetadataList, { ...defaultPayload, ...payload }, headers)

    if (status === 'Success') {
      yield put(qcMetadataListSuccess(data))
      yield put(qcMetadataSetPageNumber(payload?.page_num || 1))
    } else {
      yield put(qcMetadataListFailure(message))
      message && notification.error({ message })
    }
  } catch (error) {
    yield put(qcMetadataListFailure(error))
  }
}

function* qcGetMetadataSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }

    const {
      data: {
        result: { status, data, message }
      }
    } = yield call(getMetadata, payload, headers)

    if (status === 'Success') {
      yield put(qcGetMetadataSuccess(data))
    } else {
      yield put(qcGetMetadataFailure(message))
      message && notification.error({ message })
    }
  } catch (error) {
    yield put(qcGetMetadataFailure(error))
  }
}

function* qcCreateMetadataSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const { addDetails } = yield select((state) => state.qualityCheck)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }

    const {
      data: {
        result: { status, data, message }
      }
    } = yield call(createMetadata, { ...addDetails }, headers)

    if (status === 'Success') {
      yield put(qcCreateMetadataSuccess(data))
    } else {
      yield put(qcCreateMetadataFailure(message))
      message && notification.error({ message })
    }
  } catch (error) {
    yield put(qcCreateMetadataFailure(error))
  }
}

function* qcUpdateMetadataSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }

    const {
      data: {
        result: { status, data, message }
      }
    } = yield call(updateMetadata, payload, headers)

    if (status === 'Success') {
      yield put(qcUpdateMetadataSuccess(data))
      if (payload?.is_draft === false) {
        yield put(qcMetadataListRequest())
      }
    } else {
      yield put(qcUpdateMetadataFailure(message))
      message && notification.error({ message })
    }
  } catch (error) {
    yield put(qcUpdateMetadataFailure(error))
  }
}

function* qcDeleteMetadataSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { metadataList } = yield select((state) => state.qualityCheck)
    const isLastRecord = metadataList?.length === 1

    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }

    const {
      data: {
        result: { status, data, message }
      }
    } = yield call(deleteMetadata, payload, headers)


    if (status === 'Success') {
      yield put(qcDeleteMetadataSuccess(data))
      yield put(qcMetadataListRequest({
        page_num: isLastRecord ? payload.page_num - 1 > 0 ? payload.page_num - 1 : 1 : payload.page_num
      }))
    } else {
      yield put(qcDeleteMetadataFailure(message))
      message && notification.error({ message })
    }
  } catch (error) {
    yield put(qcDeleteMetadataFailure(error))
  }
}

function* qcGetNameDetailsSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }

    const {
      data: {
        result: { status, data, message }
      }
    } = yield call(getNameDetails, payload, headers)

    if (status === 'Success') {
      yield put(qcGetNameDetailsSuccess(data))
    } else {
      yield put(qcGetNameDetailsFailure(message))
      message && notification.error({ message })
    }
  } catch (error) {
    yield put(qcGetNameDetailsFailure(error))
  }
}

function* qcCreateNameDetailsSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }

    const {
      data: {
        result: { status, data, message }
      }
    } = yield call(createNameDetails, payload, headers)


    if (status === 'Success') {
      yield put(qcCreateNameDetailsSuccess(data))
    } else {
      yield put(qcCreateNameDetailsFailure(message))
      message && notification.error({ message })
    }
  } catch (error) {
    yield put(qcCreateNameDetailsFailure(error))
  }
}

function* qcUpdateNameDetailsSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }

    const {
      data: {
        result: { status, data, message }
      }
    } = yield call(updateNameDetails, payload, headers)

    if (status === 'Success') {
      yield put(qcUpdateNameDetailsSuccess(data))
    } else {
      yield put(qcUpdateNameDetailsFailure(message))
      message && notification.error({ message })
    }
  } catch (error) {
    yield put(qcUpdateNameDetailsFailure(error))
  }
}

function* qcDeleteNameDetailsSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }

    const {
      data: {
        result: { status, data, message }
      }
    } = yield call(deleteNameDetails, payload, headers)

    if (status === 'Success') {
      yield put(qcDeleteNameDetailsSuccess(data))
    } else {
      yield put(qcDeleteNameDetailsFailure(message))
      message && notification.error({ message })
    }
  } catch (error) {
    yield put(qcDeleteNameDetailsFailure(error))
  }
}

function* qcGetImportDataOptionsSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }

    const {
      data: {
        result: { status, data, message }
      }
    } = yield call(getMetadataListShort, payload, headers)
    if (status === 'Success') {
      yield put(qcGetImportDataOptionsSuccess(data))
    } else {
      yield put(qcGetImportDataOptionsFailure(message))
      message && notification.error({ message })
    }
  } catch (error) {
    yield put(qcGetImportDataOptionsFailure(error))
  }
}

function* qcImportDataSaga({ payload }: any): Generator<any, any, any> {
  try {
    const { defaultHeaders } = yield select((state) => state)
    const headers = { ...defaultHeaders, token: jwtManager.getToken() }

    const {
      data: {
        result: { status, data, message }
      }
    } = yield call(importData, payload, headers)

    if (status === 'Success') {
      yield put(qcImportDataSuccess(data))
      yield put(qcGetMetadataRequest({
        profile_id: payload?.to_profile_id
      }))
    } else {
      yield put(qcImportDataFailure(message))
      message && notification.error({ message })
    }
  } catch (error) {
    yield put(qcImportDataFailure(error))
  }
}

export default function* rootSaga(): Generator<any, any, any> {
  yield takeLatest(qcGetFilesRequest, qcGetFilesSaga)
  yield takeLatest(qcApplyFilesRequest, qcApplyFilesSaga)
  yield takeLatest(qcFileExtractionsRequest, qcFileExtractionsSaga)
  yield takeLatest(qcSaveChangesAndApplyRequest, qcSaveChangesAndApplySaga)

  yield takeLatest(qcDeleteFileRequest, qcDeleteFileSaga)

  yield takeLatest(qcGetRegisterLinkDataRequest, qcGetRegisterLinkDataSaga)
  yield takeLatest(qcValidateLinkRequest, qcValidateLinkSaga)
  yield takeLatest(qcLinkRecurringCycleRequest, qcLinkRecurringCycleSaga)
  yield takeLatest(qcSyncOnDemandRequest, qcSyncOnDemandSaga)

  yield takeLatest(qcGenerateReportRequest, qcGenerateReportSaga)

  yield takeLatest(qcMetadataListRequest, qcMetadataListSaga)
  yield takeLatest(qcGetMetadataRequest, qcGetMetadataSaga)
  yield takeLatest(qcCreateMetadataRequest, qcCreateMetadataSaga)
  yield takeLatest(qcUpdateMetadataRequest, qcUpdateMetadataSaga)
  yield takeLatest(qcDeleteMetadataRequest, qcDeleteMetadataSaga)

  yield takeLatest(qcGetNameDetailsRequest, qcGetNameDetailsSaga)
  yield takeLatest(qcCreateNameDetailsRequest, qcCreateNameDetailsSaga)
  yield takeLatest(qcUpdateNameDetailsRequest, qcUpdateNameDetailsSaga)
  yield takeLatest(qcDeleteNameDetailsRequest, qcDeleteNameDetailsSaga)

  yield takeLatest(qcGetImportDataOptionsRequest, qcGetImportDataOptionsSaga)
  yield takeLatest(qcImportDataRequest, qcImportDataSaga)
}
