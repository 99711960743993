import { StarOutlined } from "@ant-design/icons";
import { Col, Form, Input, Modal, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { AsyncStates } from "src/constants";
import { modelsConfigRequest } from "src/store/actions/formulate";
import {
  addFavouritesInInverseRequest,
  getInverseConstraintDetailsRequest,
} from "src/store/actions/inverseModel";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";

const InverseAddToFavoritesModal = ({ fromState }: { fromState: "success" | "failed" }) => {
  const [t] = useTranslate();
  const [form] = useForm();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const predictionId = searchParams.get("predId");
  const [showAddFavoriteModal, setShowAddFavoriteModal] = useState(false);

  const versions = useSelector(
    (state: StoreState) => state.newInverseModel.inverseVersionList
  );
  const invConstraintData = useSelector(
    (state: StoreState) => state.inverseModel.inverseConstraintDetails
  );
  const addFavouritesInInverseStatus = useSelector(
    (state: StoreState) => state.inverseModel.addFavouritesInInverseStatus
  );
  const { configData, modelConfigData } = useSelector(
    (state: StoreState) => state.formulate
  );
  const defaultInverseConfigurationData = useSelector(
    (state: StoreState) => state.inverseModel.inverseConfigData
  );

  const extractNumericValue = (value: any) => {
    return value.replace(/[^0-9.]/g, "").trim();
  };

  const dataByStage = useMemo(() => {
    const formattedData: Record<number, any> = {};
    const expDataByStage = invConstraintData?.data;

    Object.keys(expDataByStage || {}).forEach((stage, idx) => {
      let stageNumber = extractNumericValue(stage);
      if (stageNumber === "" || isNaN(stageNumber)) stageNumber = idx;
      stageNumber = Number(stageNumber);
      formattedData[stageNumber] = expDataByStage[stage];
    });

    return formattedData;
  }, [invConstraintData]);

  useEffect(() => {
    if (!!configData.length && Number(versions[0])) {
      const currentModelData = configData.find(
        (config: any) => config.version === Number(versions[0])
      );
      dispatch(
        modelsConfigRequest({
          version: versions[0],
          ...(currentModelData?.is_multistage && {
            isMultiStage: currentModelData?.is_multistage,
            stage_name: currentModelData?.all_stages?.[0] ?? "Stage 1",
          }),
        })
      );
    }
  }, [dispatch, configData, versions]);

  useEffect(() => {
    if (predictionId) {
      dispatch(
        getInverseConstraintDetailsRequest({
          prediction_id: predictionId,
        })
      );
    }
  }, [predictionId, dispatch]);

  const getRangeParameterList = () => {
    const value: any = {};

    Object.keys(dataByStage || {}).forEach((stage) => {
      value[+stage] = [];
      const expData = dataByStage[+stage];

      Object.keys(expData?.parameter_constraints || {}).forEach((key) => {
        const range = expData?.parameter_constraints[key]?.range;
        const category = expData?.parameter_constraints[key]?.category;
        if (range && !Array.isArray(range)) {
          value[+stage].push({
            ...expData?.parameter_constraints[key],
            ...range,
            parameter: key,
            type: expData?.category_wise_constraints[category]?.type
          });
        }
      });
    });

    return value;
  };

  const getCategoricalInputList = () => {
    const value: any = {};

    Object.keys(dataByStage || {}).forEach((stage) => {
      value[+stage] = [];
      const expData = dataByStage[+stage];

      Object.keys(expData?.parameter_constraints || {}).forEach((key) => {
        const range = expData?.parameter_constraints[key]?.range;
        const category = expData?.parameter_constraints[key].category;
        if (range && Array.isArray(range)) {
          value[+stage].push({
            category,
            data: range,
            parameter: key,
            type: expData?.category_wise_constraints[category]?.type
          });
        }
      });
    });

    return value;
  };

  const getItemConstraintForCategory = (category: string, stage: any) => {
    const value: any = {};

    const expData = dataByStage[+stage];

    Object.keys(expData?.parameter_constraints || {}).forEach((key) => {
      if (expData?.parameter_constraints[key]?.category === category) {
        const range = expData?.parameter_constraints[key]?.range;
        if (range && Array.isArray(range)) {
          // categorical parameter
          value[key] = range;
        } else {
          // numerical parameter
          value[key] = {
            ...expData?.parameter_constraints[key],
            parameter: key,
          };
        }
      }
    });

    return value;
  };

  const getCatwiseRangeParameterList = () => {
    const value: any = {};

    Object.keys(dataByStage || {}).forEach((stage) => {
      value[+stage] = [];
      const expData = dataByStage[+stage];

      Object.keys(expData?.category_wise_constraints || {}).forEach(
        (category) => {
          const currentType =
            expData?.category_wise_constraints[category]?.type;
          if (
            !value[+stage].find((e: any) => e.parameter_type === currentType)
          ) {
            value[+stage].push({
              categories: [],
              parameter_type: currentType,
            });
          }

          const typeIndex = value[+stage].findIndex(
            (e: any) => e.parameter_type === currentType
          );
          value[+stage]?.[typeIndex]?.categories.push({
            parameter_constraints_categorical: [],
            item_constraints: getItemConstraintForCategory(category, stage),
            ...expData?.category_wise_constraints[category],
            category,
          });
        }
      );
    });

    return value;
  };

  const getNumericalProperties = () => {
    const value: any = {};

    Object.keys(dataByStage || {}).forEach((stage) => {
      value[+stage] = [];
      const expData = dataByStage[+stage];

      Object.keys(expData?.objectives || {}).forEach((key) => {
        const currentData = expData?.objectives[key];
        if (!Array.isArray(currentData)) {
          const isRange = typeof currentData === "object";

          value[+stage].push({
            max: isRange ? currentData?.max : null,
            min: isRange ? currentData?.min : null,
            modelType: isRange ? "range" : currentData,
            parameter: key,
            priority: expData?.priority_list?.[key],
            value: isRange ? "" : currentData,
            output_range_optimal: `(${expData?.output_range_optimal?.[key]?.min} - ${expData?.output_range_optimal?.[key]?.max})`,
          });
        }
      });
    });

    return value;
  };

  const getCategoricalRange = () => {
    const value: any = {};

    Object.keys(dataByStage || {}).forEach((stage) => {
      value[+stage] = [];
      const expData = dataByStage[+stage];

      Object.keys(expData?.objectives || {}).forEach((key) => {
        const currentData = expData?.objectives[key];
        const optimalRange = Array.isArray(expData?.output_range_optimal?.[key])
          ? expData?.output_range_optimal?.[key]
          : [];
        if (Array.isArray(currentData)) {
          value[+stage].push({
            modelType: "range",
            parameter: key,
            output_range_optimal: `${optimalRange.join(", ")}`,
            val: currentData,
            checked: false,
          });
        }
      });
    });

    return value;
  };

  const getSelectAllIngredientsInCategory = () => {
    const value: any = {};

    Object.keys(dataByStage || {}).forEach((stage) => {
      value[+stage] = { numerical: {}, categorical: {} };
    });

    return value;
  };

  const getCrossCategoryValues = (expData: any) => {
    let value = {
      categories: [],
      sum: {
        min: null,
        max: null,
      },
      use_default: false,
    };

    if (Object.keys(expData?.cross_category_constraints || {}).length > 0) {
      value = {
        ...value,
        ...expData?.cross_category_constraints,
      };
    }

    return value;
  };

  const getIsCrossCategorical = () => {
    const value: any = {};

    Object.keys(dataByStage || {}).forEach((stage) => {
      const expData = dataByStage[+stage];
      value[+stage] = getCrossCategoryValues(expData).categories.length > 0;
    });

    return value;
  };

  const getCrossCategoricalSelectedCategories = () => {
    const value: any = {};

    Object.keys(dataByStage || {}).forEach((stage) => {
      const expData = dataByStage[+stage];
      value[+stage] = getCrossCategoryValues(expData).categories;
    });

    return value;
  };

  const getIsCategoricalConstraintUseDefault = () => {
    const value: any = {};

    Object.keys(dataByStage || {}).forEach((stage) => {
      const expData = dataByStage[+stage];
      value[+stage] = getCrossCategoryValues(expData).use_default;
    });

    return value;
  };

  const getCrossCategoricalValues = () => {
    const value: any = {};

    Object.keys(dataByStage || {}).forEach((stage) => {
      const expData = dataByStage[+stage];
      value[+stage] = getCrossCategoryValues(expData).sum;
    });

    return value;
  };

  const getInverseConfigs = () => {
    const value: any = {};

    Object.keys(dataByStage || {}).forEach((stage) => {
      const expData = dataByStage[+stage];
      value[+stage] = {};
      Object.keys(expData?.custom_inverse_configs || {}).forEach((key) => {
        value[+stage][key] = {
          ...(defaultInverseConfigurationData?.[key] || {}),
          ...expData?.custom_inverse_configs[key],
        };
      });
    });

    return value;
  };

  const getCategoryConstraintList = () => {
    const value: any = {};

    Object.keys(dataByStage || {}).forEach((stage) => {
      const expData = dataByStage[+stage];
      value[+stage] = [];
      Object.keys(expData?.category_wise_constraints || {}).forEach(
        (category) => {
          const currentData = expData?.category_wise_constraints?.[category];
          if (currentData?.sum_of_category_quantity) {
            value[+stage].push({
              category: category,
              constraint: "sum_of_category_quantity",
              max: currentData?.sum_of_category_quantity?.max,
              min: currentData?.sum_of_category_quantity?.min,
              type: currentData.type,
            });
          }
          if (currentData?.count_of_category_items) {
            value[+stage].push({
              category: category,
              constraint: "count_of_category_items",
              max: currentData?.count_of_category_items?.max,
              min: currentData?.count_of_category_items?.min,
              type: currentData.type,
            });
          }
        }
      );
    });

    return value;
  };

  const submitForm = (values: any) => {
    const modelData = modelConfigData[0];
    const favouritesData = {
      model_name: `${modelData.objective} (${modelData.comments})`,
      model_version: modelData.version,
      user_input_data: {
        range_parameter_list: getRangeParameterList(), // parameter_constraints,
        categorial_input_list: getCategoricalInputList(),
        catwise_range_parameter_list: getCatwiseRangeParameterList(), // category_wise_constraints filter by type,
        numerical_properties: getNumericalProperties(), // Objectives if min max, range, or text & priority_list,
        categorical_range: getCategoricalRange(), // Objectives if key value is array
        select_all_ingredients_in_category: getSelectAllIngredientsInCategory(),
        is_cross_categorical: getIsCrossCategorical(), // cross_category_constraints
        cross_categorical_selected_categories:
          getCrossCategoricalSelectedCategories(), // cross_category_constraints
        is_categorical_constraints_use_default:
          getIsCategoricalConstraintUseDefault(), // cross_category_constraints
        cross_categorical_values: getCrossCategoricalValues(), // cross_category_constraints
        inverse_configuration_data: getInverseConfigs(), // custom_inverse_configs
        cat_constraints_list: getCategoryConstraintList(), // category_wise_constraints[category][key]
      },
      ...values,
    };
    // console.log("here", favouritesData);
    dispatch(addFavouritesInInverseRequest(favouritesData));
  };

  const handleClose = useCallback(() => {
    form.resetFields();
    setShowAddFavoriteModal(false);
  }, [form, setShowAddFavoriteModal]);

  useEffect(() => {
    if (addFavouritesInInverseStatus === AsyncStates.SUCCESS) {
      handleClose();
    }
  }, [addFavouritesInInverseStatus, handleClose]);

  return (
    <>
      <Modal
        open={showAddFavoriteModal}
        onCancel={() => {
          handleClose();
        }}
        title={t("common.addToFavourites")}
        footer={null}
      >
        <Row style={{ marginBottom: "12px" }}>
          <Col>{t("aiEngine.addToFavouriteNote")}</Col>
        </Row>
        <Form onFinish={submitForm} layout="vertical" form={form}>
          <Form.Item
            name={"title"}
            rules={[{ required: true }]}
            label={t("common.title")}
          >
            <Input type="text" />
          </Form.Item>

          <Row justify="end">
            <Form.Item>
              <StyledButton
                htmlType="submit"
                type="primary"
                loading={addFavouritesInInverseStatus === AsyncStates.LOADING}
              >
                {t("common.addToFavourites")}
              </StyledButton>
            </Form.Item>
          </Row>
        </Form>
      </Modal>
      <StyledButton
        type="default"
        icon={<StarOutlined />}
        loading={addFavouritesInInverseStatus === AsyncStates.LOADING}
        onClick={() => setShowAddFavoriteModal(true)}
      >
        {t("common.addToFavourites")}
      </StyledButton>
    </>
  );
};

export default React.memo(InverseAddToFavoritesModal);
