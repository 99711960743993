import { Tooltip } from 'antd'
import useTranslate from 'src/utils/useTranslate'
import { QualityCheckStatus } from '..'
import { AlertIcon, FailIcon, InProgressIcon, NotScannedIcon, PassIcon } from '.'

const Status = ({ qc_details, status }: any) => {
  const [t] = useTranslate()

  if(!qc_details || status === QualityCheckStatus.UPLOADED) {
    return <InProgressIcon/>
  }

  if (status === QualityCheckStatus.NOT_SCANNED) {
    return (
      <Tooltip title={t('qc.notScanned')}>
        <NotScannedIcon />
      </Tooltip>
    )
  }

  if (status === QualityCheckStatus.COMPLETED) {
    if (qc_details?.total === 0 && qc_details?.failed === 0 && qc_details?.passed === 0) {
      return (
        <Tooltip title={t('qc.unsupportedFile')}>
          <FailIcon />
        </Tooltip>
      )
    }
    if (qc_details?.alert && qc_details?.alert > 0) {
      return (
        <Tooltip title={t('qc.alert')}>
          <AlertIcon />
        </Tooltip>
      )
    }
    if (qc_details?.total - qc_details?.passed > 0) {
      return <FailIcon />
    }
    if (qc_details?.total - qc_details?.passed === 0) {
      return <PassIcon />
    }
  }

  return null
}

export default Status
