import { useState } from 'react'
import AddDetails from './AddDetails'
import InputCriteria from './InputCriteria'
import MapMetadataTitles from './MapMetadataTitles'
import MapTestTitles from './MapTestTitles'
import ImportDataModal from './ImportDataModal'
import { stepReverseMapping } from '../MetadataDrawer'
import ReportMapping from './ReportMapping'

const StepContent = ({ step }: { step: number }) => {
  const [importDataModalVisible, setImportDataModalVisible] = useState<boolean>(false)

  const renderSteps = () => {
    if (step === 0) {
      return <AddDetails />
    }
    if (step === 1) {
      return (
        <MapMetadataTitles
          step={step}
          openImportModal={() => {
            setImportDataModalVisible(true)
          }}
        />
      )
    }
    if (step === 2) {
      return (
        <MapTestTitles
          step={step}
          openImportModal={() => {
            setImportDataModalVisible(true)
          }}
        />
      )
    }
    if (step === 3) {
      return (
        <InputCriteria
          step={step}
          openImportModal={() => {
            setImportDataModalVisible(true)
          }}
        />
      )
    }

    if(step === 4) {
      return <ReportMapping step={step} />
    }
    return null
  }
  return (
    <>
      {renderSteps()}
      {step > 0 && step < 4 && <ImportDataModal step={stepReverseMapping[step]} isOpen={importDataModalVisible} setIsOpen={setImportDataModalVisible} />}
    </>
  )
}

export default StepContent
