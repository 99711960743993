import { Form, Input, Modal, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AsyncStates } from "src/constants";
import {
  createSupplierClear,
  createSupplierRequest,
  getSuppliersSuccess,
} from "src/store/actions/inventoryV2";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton";
import { trimWhiteSpacesAndSubmitForm } from "src/utils/general/trimmer";
import useTranslate from "src/utils/useTranslate";

type SupplierModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export const SupplierModal = ({ open, setOpen }: SupplierModalProps) => {
  const dispatch = useDispatch();

  const createSupplierResponse = useSelector(
    (state: StoreState) => state.inventoryV2.createSupplier
  );
  const suppliers = useSelector(
    (state: StoreState) => state.inventoryV2.getSuppliers.suppliers
  );

  const [form] = useForm();
  const onFinish = (values: any) => {
    // console.log("Received values:", values);
    dispatch(createSupplierRequest(values));
    // Handle form submission logic here
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    const handleClose = () => {
      form.resetFields();
      setOpen(false);
    };

    if (createSupplierResponse.status === AsyncStates.SUCCESS) {
      message.success(`Supplier added`);
      if (createSupplierResponse.data) dispatch(getSuppliersSuccess([...suppliers, createSupplierResponse.data]))
      dispatch(createSupplierClear());
      handleClose();
    }
  }, [dispatch, setOpen, createSupplierResponse.status, form, createSupplierResponse.data, suppliers]);

  const [t] = useTranslate();

  return (
    <Modal
      title={"Add Supplier"}
      footer={null}
      width={420}
      open={open}
      onCancel={() => setOpen(false)}
      maskClosable={false}
    >
      <Form
        form={form}
        onFinish={trimWhiteSpacesAndSubmitForm(onFinish)}
        onFinishFailed={onFinishFailed}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input supplier name!" }, { whitespace: true, message: "Please input supplier name!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
        >
          <Input type="email" />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <StyledButton type="primary" htmlType="submit">
            {t("common.submit")}
          </StyledButton>
        </Form.Item>
      </Form>
    </Modal>
  );
};
