import { PlusOutlined, ContainerOutlined } from '@ant-design/icons'
import { Space } from 'antd'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { qcMetadataStepErrors, qcMetadataUpdate } from 'src/store/actions/qualityCheck';
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import useTranslate from 'src/utils/useTranslate'
import StepTitle from './StepTitle'
import CriteriaTable from './CriteriaTable'

const InputCriteria = ({ step, openImportModal }: { step: number; openImportModal: () => void }) => {
  const [t] = useTranslate()
  const dispatch = useDispatch()

  const { getMetadataResponse } = useSelector((state: StoreState) => state.qualityCheck)

  const [inputCriteria, setInputCriteria] = useState<any[]>([...(getMetadataResponse?.input_criteria || [])])

  // Updating criteria
  useEffect(() => {
    dispatch(qcMetadataUpdate({ input_criteria: inputCriteria }))
    dispatch(qcMetadataStepErrors({ input_criteria: false }))
  }, [dispatch, inputCriteria])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '90%',
        width: '100%',
        flexGrow: 1
      }}
    >
      {/* Action bar */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0.75rem 1.5rem',
          paddingTop: '1.5rem',
          borderBottom: '1px solid #f0f0f0'
        }}
      >
        <Space>
          <StepTitle step={step} />
          <StyledButton
            icon={<PlusOutlined />}
            size="small"
            style={{
              fontSize: '0.75rem',
              fontWeight: 500
            }}
            type={'link'}
            onClick={() => {
              setInputCriteria((prev) => [
                ...prev,
                {
                  test: '',
                  method: '',
                  unit: '',
                  type: '',
                  operator: '',
                  value: '',
                  mapping: ''
                }
              ])
            }}
          >
            {t('common.addNew')}
          </StyledButton>
        </Space>
        <StyledButton
          size="small"
          style={{
            fontSize: '0.75rem',
            fontWeight: 500
          }}
          icon={<ContainerOutlined />}
          onClick={openImportModal}
        >
          {t('common.importData')}
        </StyledButton>
      </div>

      {/* Table/Mapper */}
      <div
        style={{
          height: 'calc(100% - 4rem)',
          width: '100%',
          padding: '1.5rem 2rem'
        }}
      >
        <CriteriaTable
          data={inputCriteria}
          setData={setInputCriteria}
        />
      </div>
    </div>
  )
}

export default InputCriteria
